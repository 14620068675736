import './vendor';
import vars from './helpers';
import lazyLoading from './modules/lazyLoading';
import Swiper, { Navigation, Pagination, Thumbs, Grid, Autoplay } from 'swiper';
import { Fancybox } from '@fancyapps/ui';
import Choices from 'choices.js';
import inputmask from 'inputmask/dist/inputmask';

Swiper.use([Navigation, Pagination, Thumbs, Grid, Autoplay]);

let app = {
	global: {
		init() {
			Fancybox.defaults.dragToClose = false;
			Fancybox.defaults.autoFocus = false;
			lazyLoading.init();
			app.tables.init();
		},
	},
	tables: {
		init() {
			const tables = document.querySelectorAll('table');
			const create = (table) => {
				if (!table.closest('.table') && !table.closest('.basket')) {
					const wrap = document.createElement("div");

					wrap.classList.add('table');
					table.parentElement.insertBefore(wrap, table)
					wrap.appendChild(table);
				}
			};

			tables.forEach((table) => {
				create(table);
			});
		},
	},
	select: {
		init(module) {
			const item = module.querySelector('select');

			let choices = null;

			const init = () => {
				choices = new Choices(item, {
					searchEnabled: module.dataset.search === 'Y',
					removeItems: false,
					shouldSort: false,
					itemSelectText: '',
					searchPlaceholderValue: 'Введите значение',
					noResultsText: 'Не найдено',
					// renderSelectedChoices: 'always',
				});
			};

			init();

			if (item.closest('form')) {
				item.closest('form').addEventListener('reset', () => {
					choices.destroy();
					init();
				});
			}

			module.addEventListener('destroy', () => {
				choices.destroy();
			});

			module.addEventListener('init', () => {
				init();
			});
		},
	},
	header: {
		init(module) {
			module.querySelector('.header__burger').addEventListener('click', (e) => {
				e.preventDefault();
				module.classList.toggle('is-show-menu');
			});
		},
	},
	headerCatalog: {
		init(module) {
			const menuList = module.querySelectorAll('.header__catalog__menu > li > a');
			const menuListClose = () => {
				menuList.forEach((item) => {
					const parent = item.closest('li');

					parent.classList.remove('is-show-sub-dropdown');
				});
			};
			let sliders = [];
			const initSliders = () => {
				module.querySelectorAll('.header__catalog__list').forEach((item, index) => {
					if (!vars.isDesktop() && !sliders[index]) {
						sliders[index] = new Swiper(item.querySelector('.swiper'), {
							slidesPerView: 1,
							spaceBetween: 30,
							autoHeight: true,
							pagination: {
								el: item.querySelector('.pager-dots'),
								clickable: true,
							},
							breakpoints: {
								768: {
									slidesPerView: 2,
								},
							},
						});
					} else if (vars.isDesktop() && sliders[index]) {
						sliders[index].destroy(true, true);
						sliders[index] = 0;
					}
				});
			};

			if (vars.isDesktop()) {
				module.addEventListener('mouseenter', (e) => {
					module.classList.add('is-show-dropdown');
					sliders.forEach((slider) => {
						if (slider) {
							slider.update();
						}
					});
				});
				module.addEventListener('mouseleave', (e) => {
					module.classList.remove('is-show-dropdown');
					menuListClose();
				});

				module.querySelectorAll('.header__catalog__menu > li').forEach((item) => {
					const parent = item.closest('li');

					item.addEventListener('mouseenter', (e) => {
						menuListClose();
						parent.classList.add('is-show-sub-dropdown');
					});

					item.addEventListener('mouseleave', (e) => {
						parent.classList.remove('is-show-sub-dropdown');
					});
				});
			} else {
				module.querySelector('.header__catalog__button').addEventListener('click', (e) => {
					e.preventDefault();
					if (module.classList.contains('is-show-dropdown')) {
						module.classList.remove('is-show-dropdown');
						menuListClose();
					} else {
						module.classList.add('is-show-dropdown');
						sliders.forEach((slider) => {
							if (slider) {
								slider.update();
							}
						});
					}
				});

				menuList.forEach((item) => {
					const parent = item.closest('li');

					item.addEventListener('click', (e) => {
						e.preventDefault();

						if (parent.classList.contains('is-show-sub-dropdown')) {
							parent.classList.remove('is-show-sub-dropdown');
						} else {
							menuListClose();
							parent.classList.add('is-show-sub-dropdown');
						}
					});
				});
			}

			document.addEventListener('click', (e) => {
				if (!e.target.closest('.header__catalog')) {
					module.classList.remove('is-show-dropdown');
					menuListClose();
				}
			});

			initSliders();
			window.addEventListener('resize', initSliders);
		},
	},
	supply: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				loop: true,
				autoplay: {
					delay: 5000,
					disableOnInteraction: false,
				},
				pagination: {
					el: module.querySelector('.supply__slider__pager'),
					clickable: true,
				},
			});
		},
	},
	applications: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				slidesPerView: 2,
				spaceBetween: 10,
				grid: {
					fill: 'row',
					rows: 2,
				},
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
				navigation: {
					prevEl: module.querySelector('.arrow-prev'),
					nextEl: module.querySelector('.arrow-next'),
				},
				breakpoints: {
					768: {
						spaceBetween: 30,
					},
					1024: {
						spaceBetween: 30,
						slidesPerView: 4,
						grid: false,
					},
				},
			});
		},
	},
	popular: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				slidesPerView: 1,
				spaceBetween: 30,
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
				grid: false,
				navigation: {
					prevEl: module.querySelector('.arrow-prev'),
					nextEl: module.querySelector('.arrow-next'),
				},
				breakpoints: {
					768: {
						slidesPerView: 2,
						grid: {
							fill: 'row',
							rows: 2,
						},
					},
					1024: {
						slidesPerView: 3,
						grid: {
							fill: 'row',
							rows: module.classList.contains('popular--mini') ? 1 : 2,
						},
					},
				},
			});
		},
	},
	updatesSlider: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				slidesPerView: 1,
				spaceBetween: 30,
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
			});
		},
	},
	clients: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				slidesPerView: 2,
				spaceBetween: 40,
				loop: true,
				autoplay: {
					delay: 5000,
					disableOnInteraction: false,
				},
				grid: {
					fill: 'row',
					rows: 2,
				},
				navigation: {
					prevEl: module.querySelector('.arrow-prev'),
					nextEl: module.querySelector('.arrow-next'),
				},
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
				breakpoints: {
					768: {
						slidesPerView: 4,
						spaceBetween: 70,
						grid: false,
					},
					1024: {
						slidesPerView: 8,
						spaceBetween: 30,
						grid: false,
					},
				},
			});
		},
	},
	principlesWork: {
		init(module) {
			let slider = null;
			const init = () => {
				if (vars.isMobile() && slider === null) {
					slider = new Swiper(module.querySelector('.swiper'), {
						slidesPerView: 1,
						spaceBetween: 30,
						autoHeight: true,
						pagination: {
							el: module.querySelector('.pager-dots'),
							clickable: true,
						},
						navigation: {
							prevEl: module.querySelector('.arrow-prev'),
							nextEl: module.querySelector('.arrow-next'),
						},
					});
				} else if (!vars.isMobile() && slider !== null) {
					slider.destroy(true, true);
					slider = null;
				}
			};

			init();
			window.addEventListener('resize', init);
		},
	},
	licenses: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				slidesPerView: 1,
				spaceBetween: 20,
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
				navigation: {
					prevEl: module.querySelector('.arrow-prev'),
					nextEl: module.querySelector('.arrow-next'),
				},
				breakpoints: {
					768: {
						slidesPerView: 2,
						spaceBetween: 150,
					},
					1024: {
						slidesPerView: 4,
						spaceBetween: 40,
					},
				},
			});
		},
	},
	reviews: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				slidesPerView: 1,
				spaceBetween: 20,
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
				navigation: {
					prevEl: module.querySelector('.arrow-prev'),
					nextEl: module.querySelector('.arrow-next'),
				},
				breakpoints: {
					1024: {
						slidesPerView: 2,
						spaceBetween: 35,
					},
				},
			});
		},
	},
	filter: {
		init(module) {
			const blocks = module.querySelectorAll('.filter__block');

			blocks.forEach((block) => {
				const title = block.querySelector('.filter__block__title');
				const reset = block.querySelector('.filter__block__reset');

				title.addEventListener('click', (e) => {
					e.preventDefault();
					block.classList.toggle('is-show-content');
				});

				reset.addEventListener('click', (e) => {
					e.preventDefault();
					block.querySelectorAll('input').forEach((input) => {
						if (input.type === 'checkbox') {
							input.checked = false;
						}
					});

					if (window.updateSmartFilterAfterClear) {
						window.updateSmartFilterAfterClear();
					}
				});
			});

			module.querySelector('.filter__head').addEventListener('click', (e) => {
				e.preventDefault();
				module.classList.toggle('is-show-filter');
			});
		},
	},
	productShare: {
		init(module) {
			module.querySelector('.product__card__share__label').addEventListener('click', (e) => {
				e.preventDefault();
				module.classList.toggle('is-show-list');
			});
			document.addEventListener('click', (e) => {
				if (!e.target.closest('.product__card__share')) {
					module.classList.remove('is-show-list');
				}
			});
		},
	},
	share: {
		init(module) {
			module.addEventListener('click', (e) => {
				e.preventDefault();
				try {
					navigator.share({ title: document.title, url: window.location.href });
					console.log("Data was shared successfully");
				} catch (err) {
					console.error("Share failed:", err.message);
				}
			});
		},
	},
	miniFilter: {
		init(module) {
			module.querySelectorAll('select').forEach((select) => {
				select.addEventListener('change', () => {
					module.submit();
				});
			});
		},
	},
	showMore: {
		init(module) {
			const content = module.querySelector('.show-more__content');
			const contentWrap = module.querySelector('.show-more__content-wrap');
			const more = module.querySelector('.show-more__more');
			const moreLink = more.querySelector('a');
			const check = () => {
				module.classList.remove('is-hide-content');
				module.classList.remove('is-show-content');
				if (content.offsetHeight > contentWrap.offsetHeight) {
					module.classList.add('is-hide-content');
					more.classList.remove('is-hidden');
				} else {
					module.classList.remove('is-hide-content');
					more.classList.add('is-hidden');
				}

				moreLink.innerText = moreLink.dataset.off;
			};

			check();
			window.addEventListener('resize', check);

			moreLink.addEventListener('click', (e) => {
				e.preventDefault();

				if (module.classList.contains('is-hide-content')) {
					module.classList.remove('is-hide-content');
					module.classList.add('is-show-content');
					moreLink.innerText = moreLink.dataset.on;
				} else if (module.classList.contains('is-show-content')) {
					module.classList.remove('is-show-content');
					module.classList.add('is-hide-content');
					moreLink.innerText = moreLink.dataset.off;
				}
			});
		},
	},
	productGallery: {
		init(module) {
			const thumbs = new Swiper(module.querySelector('.product__gallery__thumbs .swiper'), {
				slidesPerView: 6,
				spaceBetween: 15,
			});

			const slider = new Swiper(module.querySelector('.product__gallery__carousel .swiper'), {
				slidesPerView: 1,
				spaceBetween: 30,
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
				navigation: {
					prevEl: module.querySelector('.product__gallery__carousel .arrow-prev'),
					nextEl: module.querySelector('.product__gallery__carousel .arrow-next'),
				},
				thumbs: {
					swiper: thumbs,
				},
			});
		},
	},
	productTabs: {
		init(module) {
			const items = module.querySelectorAll('.product__tabs__item');
			const listItems = module.querySelectorAll('.product__tabs__list li a, .product__tabs__open');

			listItems.forEach((item) => {
				item.addEventListener('click', (e) => {
					e.preventDefault();

					listItems.forEach((listItem) => {
						listItem.classList.remove('is-active');
					});

					items.forEach((tabItem) => {
						if (tabItem.dataset.item === item.dataset.item) {
							tabItem.classList.add('is-active');
							item.classList.add('is-active');
						} else {
							tabItem.classList.remove('is-active');
						}
					});
					window.dispatchEvent(new Event('resize'));
				});
			});
		},
	},
	videoListSlider: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				slidesPerView: 1,
				spaceBetween: 30,
				autoHeight: true,
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
			});
		},
	},
	reviewsListSlider: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				slidesPerView: 1,
				spaceBetween: 30,
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
			});
		},
	},
	filesSlider: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				slidesPerView: 1,
				spaceBetween: 30,
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
			});
		},
	},
	sliderDefault: {
		init(module) {
			const slider = new Swiper(module.querySelector('.swiper'), {
				slidesPerView: 1,
				spaceBetween: 30,
				pagination: {
					el: module.querySelector('.pager-dots'),
					clickable: true,
				},
			});
		},
	},
	reviewsListItem: {
		init(module) {
			const textWrap = module.querySelector('.reviews-list__item__text-wrap');
			const text = module.querySelector('.reviews-list__item__text');
			const more = module.querySelector('.reviews-list__item__more');
			const moreLink = more.querySelector('a');
			const check = () => {
				module.classList.remove('is-hide-text');
				module.classList.remove('is-show-text');
				if (text.offsetHeight > textWrap.offsetHeight) {
					module.classList.add('is-hide-text');
					more.classList.remove('is-hidden');
				} else {
					module.classList.add('is-hide-text');
					more.classList.add('is-hidden');
				}
				moreLink.innerText = moreLink.dataset.off;
			};

			moreLink.addEventListener('click', (e) => {
				e.preventDefault();

				if (module.classList.contains('is-hide-text')) {
					module.classList.remove('is-hide-text');
					module.classList.add('is-show-text');
					moreLink.innerText = moreLink.dataset.on;
				} else if (module.classList.contains('is-show-text')) {
					module.classList.remove('is-show-text');
					module.classList.add('is-hide-text');
					moreLink.innerText = moreLink.dataset.off;
				}
			});

			check();
			window.addEventListener('resize', check);
		},
	},
	print: {
		init(module) {
			module.addEventListener('click', (e) => {
				e.preventDefault();
				window.print();
			});
		},
	},
	vacanciesAccordion: {
		init(module) {
			const closeAll = () => {
				module.querySelectorAll('.vacancies__accordion__item').forEach((item) => {
					item.classList.remove('is-show-content');
				});
			};

			module.addEventListener('click', (e) => {
				if (e.target && e.target.classList.contains('vacancies__accordion__item__head')) {
					const item = e.target.closest('.vacancies__accordion__item');

					e.preventDefault();
					if (item.classList.contains('is-show-content')) {
						item.classList.remove('is-show-content');
					} else {
						closeAll();
						item.classList.add('is-show-content');
					}
				}
			});
		},
	},
	dealers: {
		init(module) {
			let map = null;
			const options = module.querySelector('.dealers__map').dataset;
			const list = module.querySelector('.swiper-wrapper');
			const filter = module.querySelector('.filter-mini');
			let slider = null;
			const sliderInit = () => {
				if (vars.isMobile() && slider === null) {
					slider = new Swiper(module.querySelector('.swiper'), {
						slidesPerView: 1,
						spaceBetween: 30,
						autoHeight: true,
						pagination: {
							el: module.querySelector('.pager-dots'),
							clickable: true,
						},
					});
				} else if (!vars.isMobile() && slider !== null) {
					slider.destroy(true, true);
					slider = null;
				}
			};

			const setMarkers = () => {
				map.geoObjects.removeAll();
				module.querySelectorAll('.dealers__item').forEach((item) => {
					map.geoObjects.add(new ymaps.Placemark([item.dataset.lat, item.dataset.lng], {}, {
						iconLayout: 'default#image',
						iconImageHref: options.marker,
						iconImageSize: [20, 20],
						iconImageOffset: [-10, -10],
					}));
				});
				if (map.geoObjects.getBounds()) {
					map.setBounds(map.geoObjects.getBounds());
				}
			};
			const getData = () => {
				vars.ajax({
					data: new FormData(filter),
					method: filter.getAttribute('method') || 'get',
					action: filter.getAttribute('action'),
					callback: (xmlhttp) => {
						if (xmlhttp.status === 200) {
							list.innerHTML = xmlhttp.response;
							setMarkers();
							if (slider !== null) {
								slider.update();
							}
						}
					},
				});
			};

			vars.scriptLoading('//api-maps.yandex.ru/2.1/?lang=ru_RU', () => {
				ymaps.ready(() => {
					map = new ymaps.Map(module.querySelector('.dealers__map'), {
						center: [options.lat, options.lng],
						zoom: options.zoom,
						controls: [],
					}, {});
					getData();
				});
			});

			sliderInit();
			filter.addEventListener('change', getData);
			window.addEventListener('resize', sliderInit);
		},
	},
	up: {
		init(module) {
			module.addEventListener('click', (e) => {
				e.preventDefault();
				window.scrollTo(0, 0);
			});
		},
	},
	file: {
		init(module) {
			const title = module.dataset.title;
			const text = module.dataset.text;
			const fileTemplate = module.querySelector('.file__template').innerHTML;
			const fileList = module.querySelector('.file__list');
			let list = null;
			const createModal = () => {
				const form = document.createElement('form');
				const titleEl = document.createElement('div');
				const textEl = document.createElement('div');
				const input = document.createElement('input');
				const content = document.createElement('div');
				const icon = document.createElement('div');
				const progress = document.createElement('div');
				const progressText = document.createElement('div');
				const progressBar = document.createElement('div');
				const progressBarProgress = document.createElement('span');
				const listWrap = document.createElement('div');
				const buttonWrap = document.createElement('div');
				const button = document.createElement('button');

				titleEl.classList.add('upload__title');
				textEl.classList.add('upload__text');
				form.setAttribute('action', module.dataset.action);
				form.setAttribute('method', module.dataset.method);
				form.classList.add('upload');
				input.classList.add('upload__input');
				input.setAttribute('type', 'file');
				input.setAttribute('accept', module.dataset.accept);
				input.setAttribute('name', 'files[]');
				if (module.dataset.type === 'multiple') {
					input.setAttribute('multiple', '');
				}
				icon.classList.add('upload__icon');

				progress.classList.add('upload__progress');
				progressText.classList.add('upload__progress__text');
				progressBar.classList.add('upload__progress__bar');

				progress.append(progressText);
				progress.append(progressBar);
				progressBar.append(progressBarProgress);

				buttonWrap.classList.add('upload__button');
				button.setAttribute('type', 'button');
				button.innerText = module.dataset.button;
				buttonWrap.append(button);

				listWrap.classList.add('upload__list');

				form.append(input);
				if (title) {
					titleEl.innerText = title;
					form.append(titleEl);
				}
				form.append(content);
				content.append(icon);
				if (text) {
					textEl.innerText = text;
					form.append(textEl);
				}

				input.addEventListener('change', () => {
					const data = new FormData(form);
					const files = input.files;
					const xmlhttp = new XMLHttpRequest();

					icon.remove();
					input.remove();
					content.append(progress);

					xmlhttp.upload.addEventListener('progress', (evt) => {
						if (evt.lengthComputable) {
							const percentComplete = evt.loaded / evt.total * 100;
							let name = '';

							files.forEach((file, index) => {
								if (percentComplete < 100 / (index + 1)) {
									name = file.name;
								}
							});
							progressText.innerText = name;
							progressBarProgress.style.width = `${percentComplete}%`;
						}
					}, false);

					xmlhttp.onreadystatechange = () => {
						if (xmlhttp.readyState === XMLHttpRequest.DONE) {
							if (xmlhttp.status === 200) {
								const response = JSON.parse(xmlhttp.response);
								if (response.list.length > 0) {
									response.list.forEach((file) => {
										let thisTemplate = fileTemplate;

										for (let param in file) {
											let regex = new RegExp(`{{${param}}}`, 'g');

											thisTemplate = thisTemplate.replace(regex, file[param]);
										}
										listWrap.insertAdjacentHTML('beforeend', thisTemplate);
									});
									list = response.list;
									textEl.remove();
									progress.remove();
									form.append(listWrap);
									form.append(buttonWrap);
								} else {
									input.value = '';
									progress.remove();
									content.append(icon);
									form.append(textEl);
									form.append(input);
								}
							}
						}
					};

					xmlhttp.open(module.dataset.method, module.dataset.action, true);

					xmlhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
					xmlhttp.send(data);
				});

				button.addEventListener('click', (e) => {
					e.preventDefault();

					list.forEach((file) => {
						let thisTemplate = fileTemplate;

						for (let param in file) {
							let regex = new RegExp(`{{${param}}}`, 'g');

							thisTemplate = thisTemplate.replace(regex, file[param]);
						}
						fileList.insertAdjacentHTML('beforeend', thisTemplate);
					});
					fileList.classList.remove('is-hidden');
					Fancybox.close(false);
				});

				new Fancybox([
					{
						src: form,
						type: 'html',
					},
				], {
					closeButton: false,
				});
			};

			module.querySelector('.file__label button').addEventListener('click', (e) => {
				e.preventDefault();
				createModal();
			});

			document.addEventListener('click', (e) => {
				if (e.target && e.target.closest('.file-item__remove')) {
					const item = e.target.closest('.file-item__remove');
					const parent = item.closest('.file-item');

					e.preventDefault();
					vars.ajax({
						method: 'post',
						action: item.getAttribute('href'),
						callback(xmlhttp) {
							if (xmlhttp.status === 200) {
								const response = JSON.parse(xmlhttp.response);

								if (response.result === true) {
									parent.remove();

									list = list.filter((file) => {
										return file.id !== response.id;
									});
								}
							}
						},
					});
				}
			});
		},
	},
	modal(title, text) {
		const div = document.createElement('div');

		div.classList.add('modal');
		div.classList.add('modal--success');

		if (title) {
			div.innerHTML = `<div class="modal__title">${title}</div>`;
		}

		if (text) {
			div.innerHTML += `<div class="modal__text">${text}</div>`;
		}

		new Fancybox([
			{
				src: div,
				type: 'html',
			},
		]);
	},
	mask: {
		init(module) {
			inputmask({
				mask: module.dataset.mask,
				showMaskOnHover: false,
				clearIncomplete: true,
			}).mask(module);
		},
	},
	form: {
		init(module) {
			module.addEventListener('submit', (e) => {
				const data = new FormData(module);
				const list = module.querySelector('.file__list');

				e.preventDefault();
				module.classList.add('is-loading');
				vars.ajax({
					data,
					method: module.getAttribute('method') || 'get',
					action: module.getAttribute('action'),
					callback: (xmlhttp) => {
						if (xmlhttp.status === 200) {
							const response = JSON.parse(xmlhttp.response);

							if (response.status === 'success') {
								module.reset();
								if (list) {
									list.innerHTML = '';
									list.classList.add('is-hidden');
								}
								Fancybox.close();
								app.modal(response.title, response.message);
							}

							if (response.status === 'error') {
								app.modal(response.title, response.message);
							}
						} else if (xmlhttp.status === 400) {
							app.modal('Error', 'There was an error 400');
						} else {
							app.modal('Error', 'something else other than 200 was returned');
						}
						module.classList.remove('is-loading');
					},
				});
			});
		},
	},
};

let initApp = (appEl, force = false) => {
	let appAttr = appEl.getAttribute('data-app');

	let split = appAttr.split('||');

	split.forEach((appName) => {
		let appNameCamel = false;

		if (appName.indexOf('-') !== -1) {
			appNameCamel = appName.replace(/(-|\s)(\S)/ug, (m) => m.toUpperCase()).replace(/-/ug, '');
		}

		if (app[appName] && (appEl.dataset[`${appNameCamel || appName}Init`] !== 'true' || force)) {
			app[appName].init(appEl);
			appEl.dataset[`${appNameCamel || appName}Init`] = 'true';
		} else if (app[appNameCamel] && (appEl.dataset[`${appNameCamel || appName}Init`] !== 'true' || force)) {
			app[appNameCamel].init(appEl);
			appEl.dataset[`${appNameCamel || appName}Init`] = 'true';
		}
	});
};

let initApps = () => {
	let apps = document.querySelectorAll('[data-app]');

	apps.forEach((appEl) => {
		initApp(appEl);
	});
};

app.global.init();

initApps();

